import * as styles from './card.module.scss'

import { Link, graphql } from 'gatsby'

import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import AdBadge from './ad'

const PostCard = ({ post, locale }) => {
  if (post === null) return null
  const title = getField(post, 'title', locale)
  const coverImage = getField(post.image, 'file', locale)
  const path = pathTo({ ...post, locale })

  return (
    <div className={styles.item}>
      <Link to={path} key={post.id} className={styles.link}>
        {(coverImage &&
          <div className={styles.visual}>
            {(post.advertisement &&
              <div className={styles.ad}>
                <AdBadge locale={locale} />
              </div>
            )}
            {(coverImage?.gatsbyImageData &&
              <GatsbyImage
                image={coverImage?.gatsbyImageData}
                alt={title}
              />
            )}
          </div>
        )}
        {(title &&
          <div className={styles.title}>
            {title}
          </div>
        )}
      </Link>
    </div>
  )
}

export default PostCard

export const query = graphql`
  fragment PromoRecipe on CMSRecipe {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    advertisement
    ...PromoImage
  }

  fragment PromoPost on CMSPost {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    advertisement
    ...PromoPostImage
    series {
      __typename
      slugDe
      slugEn
    }
  }

  fragment PromoPage on CMSPage {
    __typename
    id
    titleDe
    titleEn
    slugDe
    slugEn
    ...PromoPageImage
  }

  fragment PromoImage on CMSRecipe {
    image: coverImage {
      titleDe
      titleEn
      fileDe {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
      fileEn {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
    }
  }

  fragment PromoPostImage on CMSPost {
    image: coverImage {
      titleDe
      titleEn
      fileDe {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
      fileEn {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
    }
  }

  fragment PromoPageImage on CMSPage {
    image: coverImage {
      titleDe
      titleEn
      fileDe {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
      fileEn {
        gatsbyImageData(
          height: 490
          quality: 65
          fit: "thumb"
          placeholder: DOMINANT_COLOR
          width: 350
        )
      }
    }
  }
`
